<template>
  <div @mouseleave="stop_dragging()" @mouseup="stop_dragging()">
    <navbar

      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>

        <videos :betsy="betsy" :camera_name="cameraname"></videos>

    <!-- <events></events> -->
  </div>
</template>

<script>
//import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
//import LiveFeed from "./LiveFeed.vue";
import { eventBus } from "../main.js";
import Navbar from "./Navbar.vue";
import Videos from "./videos.vue";
export default {
  components: {
    //events,
    //LiveFeed,
    sidenav,
    Navbar,
    Videos,
  },
  props: ["cameraname"],
  data: () => ({
    betsy: 'default',
    selected: 0,
    grid_layouts: [1, 4, 6, 8, 10],
    grid_layout: 1,
  }),
  methods: {
    stop_dragging() {
      eventBus.$emit("stop_dragging");
    },
  },
  created() {
    eventBus.$on("grid_layout_1", () => {
      this.grid_layout = 1;
    });
    eventBus.$emit("off_events");
    eventBus.$on("change_selected", (selected) => {
      this.selected = selected;
    });

    //eventBus.$emit("change_layout", this.grid_layout);
  },
  mounted() {
    //this.grid_layout = parseInt(localStorage.getItem("preferedGrid"))
    //eventBus.$emit("change_layout", this.grid_layout);
  },
  watch: {
    grid_layout: function () {
      eventBus.$emit("change_layout", this.grid_layout);
    },
  },
};
</script>
<style  lang="css">
#eventDrawer > .v-navigation-drawer__content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#eventDrawer > .v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}
#gridSelector {
  float: left;
  width: 200px;
}
#sliderGroup {
  float: right;
}

.main_cam_div {
  margin: 0px;
  padding: 0px;
  margin-top: 28px;
}
</style>
