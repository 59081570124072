<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-card-title class="pb-0">
          <div class="oc_filter_main_div">
            <!-- <v-col class="pr-0 pb-0">Videos </v-col> -->

            <div>
              <div id="cameraFilterCol" v-if="camera_name_object">
                <v-autocomplete
                  :items="camera_name_object"
                  :menu-props="{ offsetOverflow: true }"
                  v-model="selected_cameras"
                  filled
                  label="Camera"
                  chips
                  class="assign_select assign_select_v ml-2 font_400"
                  height="32"
                  outlined
                  dense
                  rounded
                  small-chips
                  hide-details=""
                  deletable-chips
                  :full-width="true"
                  :allow-overflow="false"
                ></v-autocomplete>
              </div>

              <div id="vid_filter_btns">
                <v-menu
                  v-model="datetime_menu"
                  :close-on-content-click="false"
                  open-on-hover
                  offset-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      style="bottom: 1px"
                      class="assign_select ml-2 filter_button font_400 pl-0 pr-0"
                      height="32"
                      rounded
                      outlined
                      ><v-icon
                        @click="change_date(false)"
                        :disabled="!date"
                        class="mr-2 pl-1 ml-2"
                        >mdi-arrow-left</v-icon
                      >
                      <span v-bind="attrs" v-on="on">
                        <span v-if="date.length">{{
                          date + " " + weekday_selector(date)
                        }}</span
                        ><span v-else>Date</span></span
                      >

                      <v-icon
                        :disabled="!date || date == todays_date"
                        @click="change_date(true)"
                        class="ml-2 pr-1 mr-2"
                        >mdi-arrow-right</v-icon
                      >
                    </v-btn>
                  </template>

                  <v-card height="316" width="280">
                    <v-row>
                      <v-col>
                        <v-date-picker
                          v-model="date"
                          flat
                          no-title
                          id="video_dp"
                          event-color="#ff9900"
                          :max="todays_date"
                          :min="min_date"
                        ></v-date-picker>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-menu>

                <v-btn
                  class="assign_select ml-2 filter_button"
                  height="32"
                  @click="update_sort_order"
                  rounded
                  outlined
                  style="bottom: 1px"
                  color="grey"
                >
                  <v-icon v-if="sort_order == 'desc'" color="grey darken-2"
                    >mdi-sort-clock-ascending-outline</v-icon
                  >
                  <v-icon v-else color="grey darken-2"
                    >mdi-sort-clock-descending-outline</v-icon
                  >
                </v-btn>
                <!--   <v-btn
                  @click="selected_bookmark = !selected_bookmark"
                  class="assign_select ml-2 filter_button"
                  height="32"
                  style="bottom: 1px"
                  rounded
                  outlined
                  color="primary"
                >
                  <v-icon v-if="!selected_bookmark" color="primary"
                    >mdi-star-outline</v-icon
                  >
                  <v-icon v-else color="primary">mdi-star</v-icon>
                </v-btn> -->
              </div>
            </div>

            <div id="vid_pagination" v-if="videos">
              <div style="width: 96px; float: left">
                <v-select
                  class="assign_select assign_select_v ml-2 pagination_select font_400"
                  height="32"
                  outlined
                  dense
                  rounded
                  :items="[50, 100, 200]"
                  :menu-props="{ offsetY: true }"
                  :hide-details="true"
                  v-model="per_page"
                ></v-select>
              </div>

              <div class="ml-2 pagination_arrows" v-if="videos">
                <v-icon v-if="page > 1" @click="paginate(false)"
                  >mdi-chevron-left</v-icon
                >
                <v-icon class="disabled_page_arrow" v-else
                  >mdi-chevron-left</v-icon
                >
                <span id="page_number">{{ page }}</span>
                <v-icon
                  v-if="videos.length == per_page"
                  @click="paginate(true)"
                  class=""
                  >mdi-chevron-right</v-icon
                >
                <v-icon class="disabled_page_arrow" v-else
                  >mdi-chevron-right</v-icon
                >
              </div>
            </div>

            <div id="time_nav">
              <span v-if="videos">{{ time_navigation() }}</span>
            </div>
          </div>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-row
            class="ma-auto"
            justify="center"
            v-if="!loading && !show_one_video && videos && videos.length"
            data-cy="card_videos"
          >
            <div v-for="(i, index) in videos" class="" :key="i.id">
              <v-lazy
                v-model="isActive"
                :options="{
                  threshold: 0.5,
                }"
                v-if="i.camera_id"
                min-height="250"
                transition="fade-transition"
                ><v-hover v-slot:default="{ hover }">
                  <v-card
                    @click="select_video(index + (page - 1) * per_page)"
                    elevation="1"
                    rounded="false"
                    light
                    class="video_tn_card"
                    min-width="300"
                    max-width="300"
                  >
                    <video-thumbnail
                      :height="174"
                      :video_path="get_path(i)"
                    ></video-thumbnail>

                    <v-card-title class="video_card_title"
                      ><div>
                        <span class="card_video_time">{{
                          get_duration(i.media_timestamp).split(
                            "T"
                          )[1]
                        }}</span
                        ><span class="card_dayofweek">{{ weekday(get_duration(i.media_timestamp).split(
                              "T"
                            )[0]) }}</span>
                        <br /><span class="card_video_date">
                          {{
                            get_duration(i.media_timestamp).split(
                              "T"
                            )[0]
                          }}
                        </span>
                         <span class="card_video_size">
                          {{ i.width }}x{{ i.height }}
                        </span> 
                      </div>
                    </v-card-title>
                    <div class="playWrapper">
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          :z-index="2"
                          class="v_overlay"
                        />
                      </v-fade-transition>
                      <v-overlay
                        :z-index="3"
                        value="true"
                        absolute
                        class="v_duration_overlay_dn"
                        opacity="0"
                      >
                        <v-chip
                          color="rgba(0, 0, 0, 0.4)"
                          class="card_video_duration"
                        >
                          {{
                            get_length(i.num_frames, i.fps)
                          }}
                        </v-chip>
                        <v-chip
                          color="rgba(0, 0, 0, 0.4)"
                          class="card_camera_name"
                        >
                          {{ camera_name_object_2[i.camera_id] }}
                        </v-chip>
                      </v-overlay>
                      <v-overlay
                        :z-index="5"
                        value="true"
                        absolute
                        class="v_duration_overlay"
                        opacity="0"
                      >
                        <video-has-inference
                          class="inference_icon"
                          :url="get_url(i)"
                        />
                      </v-overlay>
                      <!-- <v-overlay
                        :z-index="4"
                        value="true"
                        absolute
                        class="v_duration_overlay"
                        opacity="0"
                      >
                        <v-icon
                          v-if="i.bookmarked == true"
                          class="bookmark_icon"
                          @click.stop="bookmark(i, index)"
                          color="primary"
                          :disabled="
                            !(
                              allowed_operations == 'WRITE'

                            )
                          "
                          >mdi-star</v-icon
                        >
                        <v-icon
                          v-else
                          class="bookmark_icon"
                          @click.stop="bookmark(i, index)"
                          color="primary"
                          :disabled="
                            !(
                              allowed_operations == 'WRITE'
                            )
                          "
                          >mdi-star-outline</v-icon
                        >
                      </v-overlay> -->
                    </div>
                  </v-card>
                </v-hover>
              </v-lazy>
            </div>
          </v-row>
          <v-row class="ma-auto" justify="center" v-else>
            <v-col cols="12"
              ><h1 class="no_data" v-if="!loading">No Videos</h1></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog
      v-model="dialog"
      :max-width="is_json && showSideContainers? `1440px` : '1000px'"
      min-width="350px"
      v-if="videos && selected_index != null"
      class="video-dialog"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-toolbar elevation="0">
              <h2 style="color: #0090a4">OneCup Ranching</h2>
              <v-spacer></v-spacer>
              <h2
                :class="is_json ? 'sidemenu-middle-header' : 'middle-header'"
                style="color: #0090a4"
                v-if="videos[selected_index] && videos[selected_index].camera"
              >
                {{ videos[selected_index].camera.name }}
              </h2>

              <v-spacer></v-spacer>
              <v-tooltip color="black" bottom v-if="json_file_not_present">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="width: min-content">
                    <v-switch
                      v-model="is_json"
                      label="BETSY"
                      hide-details
                      inset
                      :disabled="json_file_not_present"
                    >
                    </v-switch>
                  </div>
                </template>
                <div>Sorry! Betsy Vision not available for this video.</div>
              </v-tooltip>
              <v-switch
                v-if="!json_file_not_present"
                v-model="is_json"
                label="BETSY"
                hide-details
                inset
                :disabled="json_file_not_present"
                :loading="json_loading ? 'false' : 'primary'"
              >
              </v-switch>
              <span class="filter_heading">
                <v-icon
                  large
                  class="mt-n1"
                  @click="select_video(selected_index - 1)"
                  v-if="selected_index > 0"
                  >mdi-chevron-left</v-icon
                >
                <v-icon large class="mt-n1" v-else disabled
                  >mdi-chevron-left</v-icon
                >
                <v-icon
                  large
                  class="mt-n1"
                  @click="select_video(selected_index + 1)"
                  v-if="selected_index < videos.length - 1"
                  >mdi-chevron-right</v-icon
                >
                <v-icon large class="mt-n1" v-else disabled
                  >mdi-chevron-right</v-icon
                ></span
              >
              <v-btn icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
          <video-player
            preload
            class="vjs-big-play-centered"
            :is_json="is_json"
            :json_file_not_present="json_file_not_present"
            :videoFps="videoFps"
            :portal="portal"
            :json_loading="json_loading"
            :camera_model="camera_model"
            :customerFirstName="customerFirstName"
            :customerLastName="customerLastName"
            :navigation_button_change="navigation_button_change"
            :videoPlayerDialog="dialog"
            @update-loading="updateJsonLoading"
            :json_url="json_file_url"
            :options="{
              autoplay: true,
              controls: false,
              fluid: true,
            }"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VideoPlayer from "@/components/video_player/VideoPlayer.vue";
import videojs from "video.js";
import moment from "moment-timezone";
import { eventBus } from "../main.js";
import Vue from "vue";
import VideoHasInference from "@/components/video_has_inference.vue";
import VideoThumbnail from "../components/VideoThumbnail.vue";

// import axios from "axios";
// import * as hasura_mutations from "../graphql_hasura/mutations";

import {
  mdiFacebook,
  mdiInstagram,
  mdiLinkedin,
  mdiTwitter,
  mdiYoutube,
} from "@mdi/js";

export default {
  name: "videos",
  components: {
    VideoPlayer,
    VideoHasInference,
    VideoThumbnail,
  },

  props: ["camera_name", "betsy"],
  data: () => ({
    videos: [],
    showSideContainers : window.innerWidth >= 1450,
    facebook: mdiFacebook,
    instagram: mdiInstagram,
    linkedin: mdiLinkedin,
    twitter: mdiTwitter,
    youtube: mdiYoutube,
    indent: false,
    isActive: false,
    objects: [],
    hours: [0, 24],
    datetime_menu: false,
    show_timebar: false,
    nudge_width_menu: 0,
    mini_date_menu: false,
    date: "",
    page: 1,
    per_page: 100,
    loading: true,
    thumbnails: [],
    show_one_video: false,
    video_url: null,
    video_duration: null,
    todays_date: moment().format("YYYY-MM-DD"),
    min_date: moment().subtract(89, 'd').format("YYYY-MM-DD"),
    betsy_bool: false,
    previous_tokens: [],
    selected_cameras: undefined,
    selected_date: false,
    selected_bookmark: false,
    dialog: false,
    selected_index: null,
    sort_order: "desc",
    videos_at_dates: {},
    reset_playbackRate: false,
    is_json: false,
    modal_width: "1000px",
    json_data: "",
    site_name: "",
    json_file_not_present: false,
    json_loading: false,
    videoFps: 0,
    portal: "user",
    navigation_button_change: false,
    camera_model: "",
    customerFirstName: "",
    customerLastName: "",
    json_file_url: "",
  }),

  methods: {
    checkScreenWidth() {
      this.showSideContainers = window.innerWidth >= 1450;
    },
    weekday(date) {
      let wd = this.weekday_selector(date);
      var dayofweek;
      if (moment().diff(moment(date, "YYYY-MM-DD"), "days") == 0) {
        dayofweek = "Today";
      } else if (moment().diff(moment(date, "YYYY-MM-DD"), "days") == 1) {
        dayofweek = "Yesterday";
      } else if (wd == "Sun") {
        dayofweek = "Sunday";
      } else if (wd == "Mon") {
        dayofweek = "Monday";
      } else if (wd == "Tue") {
        dayofweek = "Tuesday";
      } else if (wd == "Wed") {
        dayofweek = "Wednesday";
      } else if (wd == "Thur") {
        dayofweek = "Thursday";
      } else if (wd == "Fri") {
        dayofweek = "Friday";
      } else if (wd == "Sat") {
        dayofweek = "Saturday";
      }
      return dayofweek;
    },
    get_length(frames, fps) {
      return new Date((frames/fps) * 1000).toISOString().slice(11, 19);
    },
    weekday_selector(date) {
      var dayofweek;
      if (moment(date, "YYYY-MM-DD").weekday() == 0) {
        dayofweek = "Sun";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 1) {
        dayofweek = "Mon";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 2) {
        dayofweek = "Tue";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 3) {
        dayofweek = "Wed";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 4) {
        dayofweek = "Thur";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 5) {
        dayofweek = "Fri";
      } else if (moment(date, "YYYY-MM-DD").weekday() == 6) {
        dayofweek = "Sat";
      }
      return dayofweek;
    },
    updateJsonLoading(newValue) {
      this.json_loading = newValue;
    },
    change_date(next) {
      if (next) {
        this.date = moment(this.date, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        this.date = moment(this.date, "YYYY-MM-DD")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }
    },
    toggleBoundingBox() {
      this.showbos = !this.showbos;
    },
    update_sort_order() {
      if (this.sort_order == "desc") {
        this.sort_order = "asc";
      } else {
        this.sort_order = "desc";
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    time_navigation() {
      if (this.videos[0]) {
        return (
          this.get_duration(
            this.videos[0].media_timestamp.slice(0, 16)
          ).replace("T", " ") +
          " - " +
          this.get_duration(
            this.videos[this.videos.length - 1].media_timestamp.slice(0, 16)
          ).replace("T", " ")
        );
      }

      return "";
    },

    select_video(index) {
      this.selected_index = index;
      this.dialog = true;
      this.navigation_button_change = !this.navigation_button_change;
      try {
        this.json_file_url =  this.get_path(this.videos[this.selected_index])
        //this.json_file_url = this.videos[this.selected_index].path;
        this.jsonPresent();
        this.playVideo();
      } catch (error) {
        return error;
      }
    },
    get_duration(duration) {
      if (this.timezeone_offset && this.timezeone_offset.timezone) {
        return moment
          .tz(duration, "YYYY-MM-DDThh:mm", "UTC")
          .tz(this.timezeone_offset.timezone)
          .format("YYYY-MM-DDThh:mm A");
      } else {
        return moment(duration, "YYYY-MM-DDThh:mm").format(
          "YYYY-MM-DDThh:mm A"
        );
      }
    },
    paginate(dir) {
      if (dir) {
        this.page += 1;
      } else {
        this.page -= 1;
      }
    },
    // async bookmark(video, index) {
    //   try {
    //     let response = await axios.post(
    //       this.$store.getters.getHasuraParams.url,
    //       {
    //         query: hasura_mutations.update_video_by_pk,
    //         variables: {
    //           pk_columns: { id: video.id },
    //           _set: { bookmarked: !this.videos[index].bookmarked },
    //         },
    //       },
    //       {
    //         headers: this.$store.getters.getHasuraParams.headers,
    //       }
    //     );
    //     if (response.errors || response.data.errors) {
    //       throw new Error("GraphQL error: " + response.errors[0].message);
    //     }
    //     this.videos[index].bookmarked = !this.videos[index].bookmarked;
    //   } catch (error) {
    //     return error;
    //   }
    // },
    get_path(video) {
      let date = moment(video.media_timestamp, "YYYY-MM-DDTHH:mm:ss");
      let year = date.format("YYYY");
      let month = date.format("MM");
      let day = date.format("DD");
      return `${video.customer_id}/${video.camera_id}/${year}/${month}/${day}/${
        video.camera_id
      }_${moment(video.media_timestamp, "YYYY-MM-DDTHH:mm:ss").format(
        "YYYY-MM-DD_HH-mm-ss"
      )}`;
    },
    get_url(video) {
      let temp_array = this.get_path(video).split(video.camera_id + "/");
      let betsy_thumbnail = temp_array[0] + video.camera_id + "/" + temp_array[1];
      return betsy_thumbnail;
    },

    popCameraNNObject() {
      this.loading = false;
    },

    async jsonPresent() {
      const url = this.get_path(this.videos[this.selected_index]);
      this.json_file_not_present = false;

      const json_file_source =
        process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";

      try {
        const response = await fetch(json_file_source, {
          credentials: "include",
        });
        this.is_json = false;
        if (!response.ok) {
          this.json_file_not_present = true;
          this.json_data = "";
          return;
        } else {
          this.is_json = true;
          this.json_file_not_present = false;
        }
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    },
    async playVideo() {
      const videoElement = document.querySelector(".video-js");
      if (!videoElement) {
        //console.log('Video element with class "video-js" not found.');
        return;
      }
      var player = videojs(videoElement);
      const url = this.get_path(this.videos[this.selected_index]);
      this.videoFps = this.videos[this.selected_index]["fps"];
      this.camera_model = this.camera_name_object_2[this.videos[this.selected_index].camera_id];
      this.customerFirstName = this.$store.getters.getUserDDB.first_name;
      this.customerLastName = this.$store.getters.getUserDDB.last_name;
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".m3u8";
      eventBus.$emit("sourceChanged", source);

      var res = await fetch(source, { credentials: "include" });
      if (res.status == 403) {
        var arr = url.replaceAll("+", "/").split("/");
        arr.splice(1, 0, "ftp");
        source = process.env.VUE_APP_COOKIE_BUCKET + arr.join("/") + ".m3u8";
        res = await fetch(source, { credentials: "include" });
      }
      const tn = await res.text();
      const tn_arr = tn.split(/#EXTINF:/);
      tn_arr.shift();
      tn_arr.pop();

      const duration_sec = this.videos[this.selected_index]["num_frames"]/this.videos[this.selected_index]["fps"]

      var v_tn = {};
      for (const i in tn_arr) {
        v_tn[
          parseInt(tn_arr[i].slice(-8, -4).replace("-", ""), 10) * duration_sec
        ] = {
          src:
            process.env.VUE_APP_COOKIE_BUCKET +
            url.replaceAll("+", "/").slice(0, -36) +
            "hls/" +
            tn_arr[i]
              .slice(10, 50)
              .replace(".", "")
              .replace("ts", "")
              .replace("t", "")
              .replace("s", "") +
            ".jpg",
        };
      }
      eventBus.$emit("thumbnailPicArray", v_tn);

      player.src({
        src: source,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
      this.site_name = this.$store.getters.getSites[0].name;
    },
  },

  async mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
    localStorage.setItem("selectedFeedPlayback", 1);
    this.selected_cameras = this.camera_name != "all" ? this.camera_name : null;
    eventBus.$emit("lf_pb_switch", 1);
  },
  created() {
    this.betsy_bool = this.betsy == "betsy";

    eventBus.$on("player_ready", () => {
      this.playVideo();
    });
  },
  computed: {
    camera_name_object_2() {
      var cam_nicknames = {};
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cam_nicknames[this.$store.getters.getCameraObjects[i].id] =
            this.$store.getters.getCameraObjects[i].name;
        }
      }
      return cam_nicknames;
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    query_params() {
      if (this.customer_id) {
        return {
          sort_direction: this.sort_order,
          camera: this.selected_cameras,
          limit: this.per_page,
          offset: (this.page - 1) * this.per_page,
          bookmark: this.selected_bookmark,
          date: this.selected_date,
        };
      }
      return undefined;
    },


    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
    timezeone_offset() {
      if (this.$store.getters.getSites) {
        return this.$store.getters.getSites[0];
      }
      return { timezone: "UTC" };
    },
    camera_name_object() {
      var cam_nicknames = [];
      if (this.$store.getters.getCameraObjects) {
        for (const i in this.$store.getters.getCameraObjects) {
          cam_nicknames.push({
            value: this.$store.getters.getCameraObjects[i].id,
            text: this.$store.getters.getCameraObjects[i].name,
          });
        }
      }

      return cam_nicknames;
    },

    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },

    auth_token() {
      return this.$store.getters.getLambdaAuthGoken;
    },
  },
  watch: {
    date() {
      if (this.date && this.timezeone_offset) {
        var selected_date = moment
          .tz(this.date, "YYYY-MM-DDThh", "UTC")
          .tz(this.timezeone_offset.timezone)
          .format("YYYY-MM-DDThh");
        if (selected_date.slice(8, 10) != this.date.slice(8, 10)) {
          selected_date = moment(selected_date, "YYYY-MM-DDThh")
            .add(1, "days")
            .format("YYYY-MM-DDThh");
        }
        this.selected_date = selected_date;
      }
    },

    query_params() {
      if (this.query_params) {
        this.$store
          .dispatch("DDB_GET_THUMBNAIL_INFO", this.query_params)
          .then(() => {
            this.loading = false
            this.videos = this.$store.getters.getThumbnails;
            this.popCameraNNObject();
          });
      }
    },
  },
};
</script>
<style lang="css" scoped>
*:focus {
  outline: none;
}
#time_nav {
  width: 100%;
}
#time_nav > span {
  font-size: 14px;
  display: block;
  width: 300px;

  float: right;
  font-weight: 400;
  padding-right: 6px;
  text-align: right;
}
#vid_filter_btns {
  width: 374px !important;
  height: 32px;
  text-align: start;
  display: inline-block;
  margin-top: 4px;
}
.pagination_arrows {
  float: right;
  margin-top: -2px;
}
#page_number {
  text-align: center;
  display: inline-block;
  width: 22px !important;
  font-size: 16px;
}
#vid_pagination {
  width: 186px;
  margin-top: 5px;
}
#cameraFilterCol {
  width: 240px;
  top: -1px;
  display: inline-block;
  position: relative;
}

.disabled_page_arrow {
  opacity: 0.5;
}

.per_page_picker {
  float: left;
  min-width: 52px !important;
  max-width: 52px !important;
  font-size: 14px;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.overlay .thumbnail {
  display: block;
}

.overlay .time {
  position: absolute;
  z-index: 2;
  right: 3px;
  bottom: 3px;
  padding: 2px 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

.v_overlay {
  background: rgba(0, 0, 0, 0.6) url("../assets/play-button.png") no-repeat
    scroll center center / 50px 50px;
  margin-bottom: 59px;
  border-radius: 0px;
  cursor: pointer;
}
#main_row {
  text-align: center;
}
.v_duration_overlay {
  background: rgba(0, 0, 0, 0);
  margin-bottom: 66px;
  text-align: left;
  z-index: 2 !important;
}
.v_duration_overlay_dn {
  background: rgba(0, 0, 0, 0);
  margin-bottom: 66px;
  text-align: left;
  z-index: 2 !important;
  align-items: end !important;
}

.large-slider >>> .v-slider {
  height: 240px;
  margin-top: 20px;
  margin-left: -60px;
  font-size: 12px;
}
.video_tn_card {
  display: block;
  z-index: 0 !important;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 12px;
  margin-left: 12px;
}
#saveButton {
  margin-bottom: 10px;
  margin-left: 30px;
}
.filler_div {
  min-width: 340px;
  display: block;

  margin-right: 12px;
  margin-left: 12px;
}
#date_picker {
  margin-bottom: -30px;
}

#num_videos_chip {
  font-size: 12px;
}
.v_cameraname_overlay {
  background: rgba(0, 0, 0, 0);
  margin-bottom: 64px;

  z-index: 2 !important;
}
.filter_button {
  font-size: 15px;
  letter-spacing: inherit !important;
  text-transform: none;
}

#page_picker {
  min-width: 74px;
  max-width: 74px;
  margin-top: 3px;
}

.card_video_date {
  font-size: 14px;
}

.card_video_size {
  font-size: 14px;
  float: right;
  padding-top: 3px;
}
.card_video_time {
  font-weight: 500;
}
.card_dayofweek {
  font-weight: 500;
  float: right;
  /* padding-top: 2px; */
}
.card_video_duration {
  float: right;
}
.card_camera_name {
  float: left;
}
.video_card_title {
  padding-top: 9px;
  line-height: 20px;
  padding-bottom: 8px;
  max-height: 63px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.video_card_title > div {
  width: 100%;
}
.bookmark_icon {
  position: absolute;
  top: -80px;
  right: -146px;
}
.inference_icon {
  position: absolute;
  top: -82px;
  right: 130px;
}
.download_icon {
  position: absolute;
  top: -80px;
  left: -146px;
}
.bookmark_icon::after {
  display: none !important;
}
.nav_arrows_f {
  float: right;
  width: 140px;
}

.video-container-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-top: 30px;
  padding: 0;
}

/* .icons {
  margin-left: 10px;
  margin-top: 5px;
}

.sidemenu-icons {
  margin-top: 5px;
  margin-left: 75px;
}

.middle-header {
  margin-left: 220px;
}

.sidemenu-middle-header {
  margin-left: 450px;
} */
</style>
