<template>
    <v-img
        :height="height"
        :width="width"
        :src="source"
        referrerpolicy="no-referrer"
        @error="error = true"
    />
</template>
<script>
export default {
    props: ['video_path', 'width', 'height'],
    data(){
        return {
            error: false
        }
    },
    methods:{
        alternatePath(p){
            var arr = p.split('/')
            arr.splice(1, 0, 'ftp')
            return arr.join('/');
        }
    },
    computed: {
        source(){
            return (!this.error) 
                    ? (this.cookie_bucket + this.video_path + '_480x270.jpg') 
                    : (this.cookie_bucket + this.alternatePath(this.video_path) + '_480x270.jpg');
        },
        cookie_bucket() {
            return process.env.VUE_APP_COOKIE_BUCKET;
        },
    },
    watch: {
        video_path(){
            this.error = false;
        }
    }
}
</script>