<template>
  <span
    ><v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          style="z-index: 20"
          v-bind="attrs"
          v-on="on"
          :color="avail_color"
          >mdi-circle</v-icon
        >
      </template>
      <span v-if="avail_color == 'green'">Video was processed by Betsy</span>
      <span v-else>Video was not processed by Betsy yet</span>
    </v-tooltip></span
  >

  <!-- <v-icon small :color="avail_color">mdi-circle</v-icon> -->
</template>

<script>
//import axios from "axios";

export default {
  props: ["url"],
  data() {
    return {
      avail_color: "grey",
    };
  },
  methods: {
    async checkJSONAvailability(url) {
      var json_file_source =
        process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";

      //     });
      await fetch(json_file_source, { credentials: "include" }).then(
        async (res) => {
          if (res.status == 403) {
            var arr = url.replaceAll("+", "/").split("/");
            arr.splice(1, 0, "ftp");
            json_file_source =
              process.env.VUE_APP_COOKIE_BUCKET + arr.join("/") + ".json.zip";
            await fetch(json_file_source, { credentials: "include" }).then(
              (res2) => {
                if (res2.status == 403) {
                  this.avail_color = "grey";
                } else {
                  this.avail_color = "green";
                }
              }
            ).catch((error) => {
          return error
      });
          } else {
            this.avail_color = "green";
          }
        }
      ).catch((error) => {
          return error
      });
    },
  },
  mounted() {
    this.checkJSONAvailability(this.url);
  },
  watch: {
    url() {
      this.checkJSONAvailability(this.url);
    },
  },
};
</script>